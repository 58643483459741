<template>
  <b-modal
    id="view-all-user-list"
    class="custom-size-modal"
    size="lg"
    centered
    no-close-on-esc
    no-close-on-backdrop
    :hide-footer="hideFooter"
  >
    <div class="d-flex justify-content-center">
      <b-card-title title-tag="h2" class="title font-weight-bold mb-0">
        <div>{{ this.notification && this.notification.name }}</div>
      </b-card-title>
    </div>
    <b-form-group label="Search Name">
      <b-form-input
        id="basicInput"
        v-model="searchUser"
        placeholder="Search Name"
      />
    </b-form-group>
    <div class="table-responsive">
      <b-skeleton-table
        v-if="show"
        :rows="5"
        :columns="3"
        :table-props="{ bordered: true, striped: true }"
      />
    </div>
    <b-table
      v-if="!show"
      :fields="fields"
      :items="items"
      striped
      responsive
      style="border-radius: 5px"
      class="mt-1"
    >
      <template #cell(name)="row">
        <div class="d-flex align-items-center">
          <b-avatar
            :variant="row.item.variant"
            :text="row.item.avtar_txt"
            :src="row.item.profile_pic"
          />
          <strong class="padding-l-10">{{ row.item.name }}</strong>
        </div>
      </template>
      <template #cell(role)="row">
        <strong>{{ row.item.role && row.item.role.name }}</strong>
      </template>
      <template #cell(action)="row">
        <b-badge
          pill
          class="font-light"
          :variant="
            channel.status === 'ERROR'
              ? 'light-danger'
              : channel.status === 'PENDING'
              ? 'light-secondary'
              : 'light-primary'
          "
          v-for="(channel, index) in row.item.channels"
          :key="index"
        >
          {{ channel.channel }}
          <feather-icon
            v-if="channel.status === 'ERROR'"
            icon="AlertTriangleIcon"
            size="18"
          />
          <span class="ico-check" v-if="channel.status === 'PENDING'"
            ><feather-icon icon="CheckIcon" size="18"
          /></span>
          <span class="ico-check" v-if="channel.status === 'SENT'"
            ><feather-icon icon="CheckIcon" size="18" /><feather-icon
              icon="CheckIcon"
              size="18"
              class="second-check"
          /></span>
        </b-badge>
      </template>
    </b-table>
    <div class="demo-spacing-0 d-flex justify-content-end">
      <b-pagination
        v-if="totalNotification > 0"
        :total-rows="totalNotification"
        v-model="currentPage"
        :per-page="perPage"
        first-number
        last-number
        class="mb-0 mt-1 mt-sm-0"
        prev-class="prev-item"
        next-class="next-item"
        :no-paging-nav="false"
        :active-class="activeClass"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </div>
    <hr />
    <div class="demo-spacing-0 d-flex justify-content-center">
      <b-button variant="outline-secondary" class="" @click="close">
        {{ $t("Notification.Close") }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BPagination,
  BCardTitle,
  BBadge,
  BFormInput,
  BSkeletonTable,
  BFormGroup
} from "bootstrap-vue";
import Badge from "@/layouts/components/Badge.vue";
import NotificationService from "@/libs/api/notification-service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BPagination,
    BCardTitle,
    BBadge,
    BFormInput,
    Badge,
    BFormGroup,
    BSkeletonTable
  },

  data() {
    return {
      searchUser: "",
      fields: [
        {
          key: "name",
          label: "Name",
          tdClass: "width",
          thClass: "text-left"
        },
        {
          key: "role",
          label: "Role",
          thClass: "text-left"
        },
        {
          key: "action",
          label: "",
          tdClass: "no-wrap"
        }
      ],
      items: [],
      totalNotification: 0,
      filter: {},
      user: {},
      show: false,
      perPage: 5,
      currentPage: 1,
      activeClass: "active",
      hideFooter: true
    };
  },
  props: ["selectedLog", "notification"],
  watch: {
    selectedLog(val) {
      this.searchUser = "";
      this.getNotificationActivityDetails();
    },
    searchUser(val) {
      this.currentPage = 1;
      this.getNotificationActivityDetails();
    },
    perPage(val) {
      this.getNotificationActivityDetails();
    },

    currentPage(newPage) {
      this.getNotificationActivityDetails();
    }
  },
  mounted() {
    this.$nextTick(() => {
      const { page, filter, perPage } = this.$route.query;
      if (filter) {
        this.filter = filter;
      }
      if (page) {
        this.currentPage = parseInt(page);
      }
      if (perPage) {
        this.perPage = parseInt(perPage);
      }
    });
    this.getNotificationActivityDetails();
  },
  methods: {
    async getNotificationActivityDetails() {
      try {
        this.show = true;
        if (!this.selectedLog) {
          return;
        }

        if (this.searchUser) {
          this.filter = [
            {
              field: "name",
              value: this.searchUser.trim(),
              operator: "ilike"
            }
          ];
        } else {
          this.filter = [];
        }
        let requestData = {
          page: this.currentPage,
          page_size: parseInt(this.perPage),
          filters: this.filter && this.filter.length > 0 ? this.filter : [],
          notification_id: this.notification.id,
          event_id: this.selectedLog
        };

        let response =
          await new NotificationService().getNotificationActivityDetails(
            requestData
          );

        if (response && response.data) {
          this.show = false;

          this.items = response.data.list.map((u) => {
            u.variant = this.getRandomBgColor();
            u.avtar_txt = this.userAvatarTxt(u.name);
            return u;
          });
          this.totalNotification =
            (response.data.pagination &&
              response.data.pagination.total_records) ||
            0;
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
    },
    close() {
      this.$bvModal.hide("view-all-user-list");
    }
  }
};
</script>
<style lang="scss">
.font-light {
  margin-right: 10px;
  padding: 5px 15px;
}
.no-wrap {
  white-space: nowrap;
}
.ico-check {
  position: relative;
  .second-check {
    position: absolute;
    top: 2px;
    left: 3px;
  }
}
</style>
