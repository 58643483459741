var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"height-100p"},[(_vm.items && _vm.items.length)?_c('div',{staticClass:"view-notification-list"},[_c('div',{staticClass:"d-flex justify-content-between title-notification"},[_c('h5',[_vm._v(_vm._s(_vm.$t("Notification.Notifications")))])]),_c('vue-perfect-scrollbar',{ref:"scrollContainer",on:{"ps-scroll-y":_vm.checkScroll}},_vm._l((_vm.items),function(item,index){return _c('div',{key:index,staticClass:"view-notification-repeater",class:!item.read ? 'light-bg-primary' : ''},[_c('b-row',[_c('div',{staticClass:"col-md-4 d-flex align-items-center"},[_c('b-media',{scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"50","src":item.unit && item.unit.icon,"text":item.unit && item.unit.avtar_txt,"variant":item.unit && item.unit.name
                      ? item.unit.variant
                      : 'primary'}})]},proxy:true}],null,true)},[_c('h6',{staticClass:"mb-0 text-overflow-ellipsis"},[_vm._v(" "+_vm._s(item.unit && item.unit.name)+" ")]),_c('small',{staticClass:"text-primary text-overflow-ellipsis"},[_c('b-link',{staticClass:"curserPointer",attrs:{"target":"_blank","to":{
                    name: 'alert-view',
                    params: { id: _vm.notification.alert_id }
                  }}},[_vm._v(_vm._s(_vm.notification && _vm.notification.alert_name)+" ")])],1)])],1),_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[_c('span',{staticClass:"text-right time-count"},[_vm._v(_vm._s(_vm.updateReportTime(item.time)))]),_c('b-avatar-group',{staticClass:"avatar-min-size",attrs:{"size":"32px"}},[_vm._l((item.users.show_list),function(user){return _c('b-avatar',{key:user.id,staticClass:"pull-up",attrs:{"button":"","src":user.avatar,"variant":user.variant,"text":user.avtar_txt},on:{"click":function($event){return _vm.showActivityDetails(item)}}})}),(
                    item.users &&
                    item.users.pagination &&
                    item.users.pagination.total_records -
                      item.users.show_list.length
                  )?_c('b-avatar',{staticClass:"pull-up",attrs:{"variant":"primary","button":"","text":("+" + (item.users &&
                    item.users.pagination &&
                    item.users.pagination.total_records -
                      item.users.show_list.length))},on:{"click":function($event){return _vm.showActivityDetails(item)}}}):_vm._e()],2)],1),_c('div',{staticClass:"d-flex justify-content-end align-items-center mrt-10"},[_c('Badge',{attrs:{"tags":item.channels,"showTextInBadge":Number(8),"event":item,"maxTagShow":Number(4)}})],1)])])],1)}),0),_c('NotificationSendList',{attrs:{"selectedLog":_vm.selectedLog,"notification":_vm.notification}})],1):_vm._e(),(!_vm.items || !_vm.items.length)?_c('div',[_c('span',{staticClass:"notification-empty-log"},[_c('div',{staticClass:"d-flex justify-content-between title-notification"},[_c('h3',[_vm._v(_vm._s(_vm.$t("Notification.Notifications")))])]),_c('span',[_c('h5',[_vm._v(_vm._s(_vm.$t("Notification.NotificationLogEmpty")))])])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }