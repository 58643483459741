<template>
  <div class="notification-alert-slection">
    <div style="position: relative">
      <b-form-group>
        <b-input-group class="">
          <b-form-input
            v-b-toggle.collapse-1
            v-model="name"
            :placeholder="$t('Notification.SelectAlert')"
            :disabled="$route.name !== 'notification-create'"
            @input="(e) => getIcon(e)"
            :class="
              visible ? 'unit-icon-input collapsed-icons' : 'unit-icon-input'
            "
            :aria-expanded="visible ? 'true' : 'false'"
            aria-controls="collapse-4"
            @click="getIcon(name)"
          ></b-form-input>
        </b-input-group>

        <b-collapse
          id="collapse-4"
          v-model="visible"
          class="unit-icon-modal mb-1 unit-selection-responsive-alert"
        >
          <div class="unit-icon-card">
            <Alert
              v-if="$route.name === 'notification-create'"
              :searchAlert="name"
              :selectedAlert="selectedAlert"
              :alertId="alertId"
              class="mt-1"
            ></Alert>
          </div>
        </b-collapse>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import Alert from "@/views/Alert/Alert.vue";
import {
  BCard,
  BCollapse,
  BButton,
  VBToggle,
  BFormInput,
  BCol,
  BInputGroup,
  BFormGroup
} from "bootstrap-vue";

// Note: Vue automatically prefixes the directive name with 'v-'

export default {
  name: "iconView",
  components: {
    BCard,

    BCollapse,
    BButton,
    VBToggle,
    BFormInput,

    BCol,

    BInputGroup,
    BFormGroup,
    Alert
  },
  props: ["alertName", "isEditable"],

  data() {
    return {
      test: "",

      name: "",
      alertId: "",
      visible: false,

      oldIcons: [],
      icons: []
    };
  },
  directives: {
    "b-toggle": VBToggle
  },
  mounted() {
    document.addEventListener("click", this.handleOutsideClick);
    this.name = this.alertName;
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleOutsideClick);
  },
  watch: {
    alertName(val) {
      this.name = val;
    }
  },
  computed: {},
  methods: {
    handleOutsideClick(event) {
      const clickedElement = event.target;
      if (!this.$el.contains(clickedElement)) {
        this.visible = false;
      }
    },
    getIcon(e) {
      if (e === "" || !e || (e && !e.trim())) {
        this.icons = this.oldIcons; // lowecase
      }
      this.visible = true;
    },
    selectedAlert(e) {
      this.name = e && e.name;
      this.visible = false;
      this.alertId = e && e.id;
      this.$emit("input", e.id);
    }
  }
};
</script>
<style lang="scss">
.notification-alert-slection {
  .unit-selection-responsive-alert {
    .adduser-btn-row {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
</style>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.unit-icon-card {
  background-color: var(--white);
  z-index: 9;
  // height: 32vh;
  overflow: auto;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  // margin-bottom: 1rem;
}
.input-prefix-img {
  border: 1px solid var(--gray4);
  height: 37.5px;
  width: 40px;
  border-radius: 7px 0px 0px 7px;
  text-align: center;
  img {
    height: 32px;
    margin: auto;
    padding: 3px;
  }
  .b-avatar-img {
    img {
      object-fit: contain;
      padding: 5px;
    }
  }
}
.unit-icon-modal {
  position: absolute;
  z-index: 9;
  padding: $px_10;
  margin-top: 0px;
  min-width: $percent_100;
  min-height: 32vh;
  padding: 5px 0;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  margin: 0;
}
.unit-image {
  .b-avatar-img {
    img {
      object-fit: contain;
      padding: 8px;
      border-radius: initial;
    }
  }
}
</style>
