<template>
  <div class="create-alert-noti">
    <div style="text-align: right"></div>
    <b-card class="">
      <div class="notification-create">
        <div class="d-flex justify-content-between align-items-center mb-1">
          <div>
            <h4 class="card-title mb-0">
              {{ $t("Notification.Notifications") }}
            </h4>
          </div>
          <div class="d-flex align-items-center">
            <div v-if="$route.name != 'notification-create'" class="">
              <b-badge pill class="font-light mr-15" variant="light-danger">
              </b-badge>
              <b-button
                variant="outline-primary"
                size="sm"
                @click="redirectToEdit"
                class="back-update mr-1"
                v-if="
                  !isEditable &&
                  checkAbility({
                    action: constants.PERMISSIONS_ACTION.UPDATE,
                    subject: constants.PERMISSIONS_MODEL.NOTIFICATION
                  }) &&
                  !notification.removed
                "
              >
                <feather-icon
                  class="text-primary curserPointer"
                  v-b-tooltip.hover.left="$t('tooTip.update')"
                  icon="EditIcon"
                  size="18"
                />
              </b-button>
            </div>

            <div
              v-if="$route.name != 'notification-create'"
              class="text-right unread-count mr-1"
            >
              <feather-icon
                icon="MenuIcon"
                v-b-tooltip.hover.left="$t('Notification.NotificationLog')"
                size="30"
                class="curserPointer"
                @click="toggleNotificationAvailable"
              />

              <b-badge pill variant="danger" v-if="unreadCount">
                {{ unreadCount }}
              </b-badge>
            </div>

            <b-button
              variant="outline-primary"
              size="sm"
              @click="redirectToNotificationPage"
              class="back-update ml-1 v2-back"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">{{ $t("back.back") }}</span>
            </b-button>
          </div>
        </div>
        <div class="notification-edit-view">
          <b-row>
            <b-col
              class="default-transform"
              :class="
                $route.name === 'notification-create'
                  ? 'col-md-12 create-notification'
                  : isNotificationAvailable
                  ? 'fullsize'
                  : 'col-md-6 view-notification'
              "
            >
              <validation-observer ref="refAlert">
                <b-form>
                  <div class="min-card-600">
                    <b-row>
                      <b-col sm="3" class="add-name">
                        <validation-provider
                          #default="{ errors }"
                          name="Name"
                          vid="Name"
                          :rules="{
                            required: true,
                            min: constants.MIN_ACC_NAME,
                            max: constants.MAX_ACC_NAME
                          }"
                        >
                          <b-form-group
                            :label="
                              $t('Notification.NotificationName')
                                ? $t('Notification.NotificationName')
                                : ''
                            "
                            :class="errors.length > 0 ? 'is-invalid' : null"
                          >
                            <b-form-input
                              id="name"
                              :disabled="!isEditable || autoCreated"
                              v-model="notification.name"
                              :placeholder="
                                $t('Notification.NotificationNamePlaceholder')
                              "
                            />
                          </b-form-group>
                          <small class="text-danger">{{
                            errors[0] ? handleError(errors[0]) : ""
                          }}</small>
                        </validation-provider>
                      </b-col>

                      <b-col sm="7" class="add-alert">
                        <legend class="bv-no-focus-ring col-form-label pt-0">
                          {{ $t("Notification.Alerts") }}
                        </legend>
                        <NotificationAlertSelection
                          v-if="$route.name === 'notification-create'"
                          :isEditable="isEditable"
                          :alertName="notification.alert_name"
                          v-model="alertId"
                        ></NotificationAlertSelection>
                        <div class="mb-1 mt-11" v-else>
                          <b-link
                            v-if="notification.removed"
                            target="_blank"
                            class="curserUnset"
                            :to="{
                              name: 'alert-view',
                              params: { id: alertId }
                            }"
                            v-on:click.prevent
                            >{{ notification.alert_name }} ({{
                              $t("Notification.Deleted")
                            }})</b-link
                          >
                          <b-link
                            v-else
                            target="_blank"
                            class="curserPointer"
                            :to="{
                              name: 'alert-view',
                              params: { id: alertId }
                            }"
                            >{{ notification.alert_name }} ({{
                              alertStatus
                                ? $t("Notification.Active")
                                : $t("Notification.InActive")
                            }})</b-link
                          >
                        </div>
                      </b-col>
                      <b-col sm="2" class="switch-alert">
                        <div class="d-flex mt-30 align-items-center">
                          <label>{{ $t("Notification.Status") }}</label>
                          <b-form-checkbox
                            :disabled="!isEditable || autoCreated"
                            checked="true"
                            class="custom-control-success"
                            v-model="notification.status"
                            name="check-button"
                            switch
                          ></b-form-checkbox>
                        </div>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col sm="12" v-if="alertId">
                        <legend class="bv-no-focus-ring col-form-label pt-0">
                          {{ $t("Notification.SearchGroupsUnits*") }} :
                        </legend>
                        <NotificationUnitsSelection
                          :isEditable="isEditable && !autoCreated"
                          :selectedUnitsData="selectedUnitsData"
                          :selectedUnitByUser="selectedUnitByUser"
                          :isClear="isClear"
                          :alertId="alertId"
                          :notification="notification"
                          :unitFun="unitFun"
                          :getUnitLookupCount="getUnitLookupCount"
                          :totalLookUpCount="totalLookUpCount"
                          :removedSelectedItem="removedSelectedItem"
                        ></NotificationUnitsSelection>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col
                        sm="12"
                        v-if="selectedUnitsData && selectedUnitsData.length"
                      >
                        <UnitsSlider
                          :selectedUnitsData="selectedUnitsData"
                          :isNotificationAvailable="isNotificationAvailable"
                          :isEditable="isEditable && !autoCreated"
                          :removeUnits="removeUnits"
                          :totalLookUpCount="totalLookUpCount"
                          :sliderConfiguration="sliderConfiguration"
                          :getNotificationSelectedUnitsOrGroups="
                            getNotificationSelectedUnitsOrGroups
                          "
                          :alertId="alertId"
                        ></UnitsSlider>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col
                        sm="12"
                        v-if="selectedUnitsData && selectedUnitsData.length"
                      >
                        <UnitChannel
                          :isEditable="isEditable"
                          :getNotificationChannel="getNotificationChannel"
                          :notificationActions="channelList"
                          :isClear="isClear"
                          :selectedChannelList="selectedChannelList"
                          :notificationData="notification"
                        ></UnitChannel>
                      </b-col>
                    </b-row>
                  </div>
                </b-form>
              </validation-observer>
            </b-col>

            <b-col
              class="col-md-6 view-notification-list-available"
              :class="{ notificationavailable: isNotificationAvailable }"
              v-if="
                $route.name !== 'notification-create' &&
                notification &&
                notification.id
              "
            >
              <NotificationBar
                :notification="notification"
                :unreadCountData="unreadCountData"
                :hideLogScreen="hideLogScreen"
              ></NotificationBar>
            </b-col>
          </b-row>
          <!-- <b-row>
          <b-col class="col-md-12 d-flex justify-content-end">
            <b-button
              class=""
              v-if="isEditable"
              variant="primary"
              :disabled="!(notification.name && notification.alert_id)"
              @click="save"
              >{{
                $route.name === "notification-create"
                  ? $t("Notification.CreateNotification")
                  : $t("Notification.Save")
              }}</b-button
            >
          </b-col>
        </b-row> -->
        </div>
      </div>
      <Loader :show="show" />
      <div class="d-flex justify-content-end">
        <b-button
          class=""
          v-if="isEditable"
          variant="primary"
          :disabled="!(notification.name && notification.alert_id)"
          @click="save"
          >{{
            $route.name === "notification-create"
              ? $t("Notification.CreateNotification")
              : $t("Notification.Save")
          }}</b-button
        >
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BButton,
  BFormInput,
  VBTooltip,
  BFormGroup,
  BRow,
  BCol,
  BFormCheckbox,
  BInputGroup,
  BForm,
  BBadge,
  BLink
} from "bootstrap-vue";

import constants from "@/utils/constants";

import Loader from "@/layouts/components/Loader.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import NotificationAlertSelection from "@/views/Notification/NotificationAlertSelection.vue";
import NotificationUnitsSelection from "@/views/Notification/NotificationUnitsSelection.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import UnitChannel from "@/views/Notification/UnitChannel.vue";
import UnitsSlider from "@/views/Notification/unitsSlider.vue";
import NotificationService from "@/libs/api/notification-service";
import UnitService from "@/libs/api/unit-service";
import NotificationBar from "@/views/Notification/NotificationBar.vue";

export default {
  components: {
    BCard,
    BButton,
    BFormInput,
    VBTooltip,
    BRow,
    BCol,
    Loader,
    BFormCheckbox,
    ValidationProvider,
    BInputGroup,
    BForm,
    BFormGroup,
    ValidationObserver,
    NotificationAlertSelection,
    NotificationUnitsSelection,
    UnitsSlider,
    UnitChannel,
    NotificationBar,
    BBadge,
    BLink
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  props: [],
  watch: {
    alertId(val) {
      this.getAlertNotificationChannels();

      if (this.$route.name === "notification-create") {
        this.selectedUnitsData = [];

        this.getSelectedUnit = {
          selected: [],
          unselected: []
        };
        this.selectedUnselectedItems = {
          unit: { selected: [], unselected: [] },
          group: { selected: [], unselected: [] }
        };
        this.selectedUnitByUser = [];
        (this.notification.preferences = []), (this.notification.units = {});

        this.selectedChannelList = [];
        this.isClear = !this.isClear;
      }
      this.notification.alert_id = val;
    },
    $route(to, from) {
      if (to.name === "notification-view") {
        this.isEditable = false;
      } else {
        if (
          this.checkAbility({
            action: constants.PERMISSIONS_ACTION.UPDATE,
            subject: constants.PERMISSIONS_MODEL.NOTIFICATION
          }) &&
          this.allowEdit
        ) {
          this.isEditable = true;
        }
      }
    }
  },
  data() {
    return {
      alertId: "",
      selectedUnitsData: [],
      removedSelectedItem: {},
      channelList: [],
      unreadCount: 0,
      totalLookUpCount: 0,
      isEditable: true,
      isClear: false,
      sliderConfiguration: {},
      currentPage: "notification-create",
      show: false,
      isHideLogScreen: false,
      getSelectedUnit: {
        selected: [],
        unselected: []
      },
      selectedUnselectedFinalItems: {
        unit: { selected: [], unselected: [] },
        group: { selected: [], unselected: [] }
      },
      selectedUnselectedItems: {
        unit: { selected: [], unselected: [] },
        group: { selected: [], unselected: [] }
      },
      selectedUnitByUser: [],
      notification: {
        name: "",
        status: true,
        alert_id: "",
        preferences: [],
        units: {},
        alert_name: "",
        removed: 0
      },
      alertStatus: false,
      autoCreated: false,
      allowEdit: false,
      selectedChannelList: [],
      isNotificationAvailable: true,
      groupUnitPage: 1,
      groupUnitPageSize: 5,
      groupUnitTotalPage: 0,
      unitsList: []
    };
  },
  mounted() {
    if (this.$route.params && this.$route.params.id) {
      this.getNotification(this.$route.params.id);
    }
    if (this.$route.name === "notification-view") {
      this.isEditable = false;
    }
    if (this.$route.name === "notification-create") {
      this.getAllAlert(true);
    }
    this.setSliderConfiguration();
  },
  methods: {
    toggleNotificationAvailable() {
      this.isNotificationAvailable = !this.isNotificationAvailable;
      this.unreadCount = 0;
      this.setSliderConfiguration();
    },
    async getAllAlert(isCall) {
      try {
        this.show = false;
        const me = this;
        if (!this.$route.query.alert_name && isCall) {
          return;
        }
        let requestData = {
          page: 1,
          page_size: 50,
          filters: [
            {
              field: "name",
              operator: "ilike",
              value: this.$route.query.alert_name
            }
          ]
        };

        let response = await new UnitService().getAlerts(requestData);

        if (response && response.data) {
          this.show = false;
          const items = response.data.list.find(
            (u) => u.name === this.$route.query.alert_name
          );
          if (items && items.name) {
            this.notification.alert_name = items.name;
            this.notification.alert_id = items.id;
            this.alertId = items.id;
          }
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
    },
    async save() {
      try {
        await this.validationForm();
        if (!this.notification || !this.notification.alert_id) {
          return false;
        }
        this.notification.units = this.notification.units || [
          { type: "GROUP", items: { selected: [], unselected: [] } },
          { type: "UNIT", items: { selected: [], unselected: [] } }
        ];

        for (let index = 0; index < this.notification.units.length; index++) {
          if (this.notification.units[index].type === "UNIT") {
            let unit = this.notification.units[index].items.selected;
            this.notification.units[index].items.selected =
              this.removeDuplicates(
                this.selectedUnselectedFinalItems.unit.selected
              );
            this.notification.units[index].items.unselected =
              this.removeDuplicates(
                this.notification.units[index].items.unselected
              );
          } else if (this.notification.units[index].type === "GROUP") {
            this.notification.units[index].items.selected =
              this.removeDuplicates(
                this.selectedUnselectedFinalItems.group.selected
              );
            this.notification.units[index].items.unselected =
              this.removeDuplicates(
                this.notification.units[index].items.unselected
              );
          }
          if (this.$route.name === "notification-create") {
            this.notification.units[index].items.unselected = [];
          }
        }

        if (
          !(
            this.notification &&
            this.notification.units &&
            this.notification.units.length &&
            ((this.notification.units[0].items &&
              this.notification.units[0].items.selected &&
              this.notification.units[0].items.selected.length) ||
              (this.notification.units[1].items &&
                this.notification.units[1].items.selected &&
                this.notification.units[1].items.selected.length))
          )
        ) {
          let units = this.unitsList;

          if (this.notification.id && this.unitsList.length) {
            for (let i = 0; i < this.unitsList.length; i++) {
              if (
                !this.notification.units[0].items.selected.length &&
                this.notification.units[0].items.unselected.length
              ) {
                if (
                  this.notification.units[0].items.unselected.includes(
                    this.unitsList[i].id
                  )
                ) {
                  let me = this;
                  units = units.filter((obj) => obj.id !== me.unitsList[i].id);
                }
              } else if (
                !this.notification.units[1].items.selected.length &&
                this.notification.units[1].items.unselected.length
              ) {
                if (
                  this.notification.units[1].items.unselected.includes(
                    this.unitsList[i].id
                  )
                ) {
                  let me = this;
                  units = units.filter((obj) => obj.id !== me.unitsList[i].id);
                }
              }
            }
          }
          if (!units.length || !this.notification.id) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("Notification.Error.SelectUnitOrGroup"),
                icon: "InfoIcon",
                variant: "danger"
              }
            });
            return;
          }
        }
        if (
          !(
            this.notification &&
            this.notification.preferences &&
            this.notification.preferences.length
          )
        ) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Notification.Error.SelectChannel"),
              icon: "InfoIcon",
              variant: "danger"
            }
          });
          return;
        }
        let response =
          this.notification && this.notification.id
            ? await new NotificationService().updateNotification(
                this.notification
              )
            : await new NotificationService().createNotification(
                this.notification
              );

        if (response && response.data) {
          this.show = false;
          //  this.alert = response.data || {};
          let message = this.$t("Notification.AddSuccessfully");
          if (this.notification && this.notification.id) {
            message = this.$t("Notification.UpdateSuccessfully");
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              text: message,
              icon: "EditIcon",
              variant: "success"
            }
          });
          this.$router.push({
            name: "notification-list"
          });
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.show = false;
      }
    },
    hideLogScreen(log) {
      if (!log) {
        this.unreadCount = 0;
      } else {
        this.isNotificationAvailable = false;
        this.unreadCount = 0;
        this.setSliderConfiguration();
      }
      this.isHideLogScreen = log;
    },
    unitFun(data, isAlreadySave) {
      data.selected.forEach((element) => {
        if (element && element.source === "GROUP") {
          if (
            !this.selectedUnselectedItems.group.selected.includes(element.id)
          ) {
            if (!element.is_selected) {
              if (!isAlreadySave) {
                this.selectedUnselectedFinalItems.group.selected.push(
                  element.id
                );
              }
            }
            this.selectedUnselectedItems.group.selected.push(element.id);
          }
        } else if (element && element.source === "UNIT") {
          if (
            !this.selectedUnselectedItems.unit.selected.includes(element.id)
          ) {
            if (!element.is_selected) {
              if (!isAlreadySave) {
                this.selectedUnselectedFinalItems.unit.selected.push(
                  element.id
                );
              }
            }
            this.selectedUnselectedItems.unit.selected.push(element.id);
          }
        }
      });
      if (this.$route.name !== "notification-create") {
        data.unSelected.forEach((element) => {
          if (element && element.source === "GROUP") {
            if (
              !this.selectedUnselectedItems.group.unselected.includes(
                element.id
              )
            ) {
              this.selectedUnselectedItems.group.unselected.push(element.id);
            }
            if (
              this.selectedUnselectedItems.group.selected.includes(element.id)
            ) {
              this.selectedUnselectedItems.group.selected =
                this.selectedUnselectedItems.group.selected.filter(
                  (e) => e.id === element.id
                );
            }
          } else if (element && element.source === "UNIT") {
            if (
              !this.selectedUnselectedItems.unit.unselected.includes(element.id)
            ) {
              this.selectedUnselectedItems.unit.unselected.push(element.id);
            }
            if (
              this.selectedUnselectedItems.unit.selected.includes(element.id)
            ) {
              this.selectedUnselectedItems.unit.selected =
                this.selectedUnselectedItems.unit.selected.filter(
                  (e) => e.id === element.id
                );
            }
          }
        });
        if (data.unSelected && data.unSelected.length === 0) {
          this.selectedUnselectedItems.group.unselected = [];
          this.selectedUnselectedItems.unit.unselected = [];
        }
      }

      // if (!isAlreadySave) {
      this.notification.units = [
        {
          type: "GROUP",
          items: this.selectedUnselectedItems.group
        },
        {
          type: "UNIT",
          items: this.selectedUnselectedItems.unit
        }
      ];
      // }
      this.selectedUnitByUser = this.notification.units;
      this.selectedUnitsData = data.selected;
    },

    redirectToEdit() {
      this.$router.push({
        name: "notification-edit",
        params: { id: this.$route.params.id }
      });
      this.isEditable = true;
      this.setSliderConfiguration();
    },
    redirectToNotificationPage() {
      // this.$router.push({
      //   name: "notification-list"
      // });
      this.$router.go(-1);
    },
    async getAlertNotificationChannels() {
      try {
        this.show = true;
        let response =
          await new NotificationService().getAlertNotificationChannels({
            alert_id: this.alertId
          });
        this.show = false;
        if (response && response.data) {
          this.channelList = (response.data && response.data.channels) || [];
        }
      } catch (err) {
        this.show = false;
      }
    },
    async getNotificationChannel(channels) {
      const Data = [];
      const tempSelected = [];

      Object.keys(channels).forEach((channel) => {
        let Obj = {};
        if (channels[channel].all) {
          Obj = {
            channel: channel,
            option: "ALL",
            items: {
              selected: [],
              unselected: []
            }
          };
        } else {
          if (channels[channel].team) {
            Obj = {
              channel: channel,
              option: "TEAM",
              items: {
                selected:
                  (channels[channel].team_data &&
                    channels[channel].team_data.selected.length &&
                    channels[channel].team_data.selected) ||
                  [],
                unselected:
                  (channels[channel].team_data &&
                    channels[channel].team_data.unselected.length &&
                    channels[channel].team_data.unselected) ||
                  []
              }
            };
          }

          if (channels[channel].people) {
            Obj.channel = channel;
            Obj.option = "SPECIFIC_USER";
            Obj.items = {
              selected:
                (channels[channel].people_data &&
                  channels[channel].people_data.selected.length &&
                  channels[channel].people_data.selected) ||
                [],
              unselected:
                (channels[channel].people_data &&
                  channels[channel].people_data.unselected.length &&
                  channels[channel].people_data.unselected) ||
                []
            };
          }
        }
        if (Object.keys(Obj).length) {
          Data.push(Obj);
        }
      });
      this.notification.preferences = Data;
      // this.selectedChannelList = Data.map((e) => {
      //   return {
      //     channel: e.channel,
      //     items: { list: (e.items && e.items.selected) || [] },
      //     option: e.option
      //   };
      // });
    },
    handleError(error) {
      return this.formattedErrorMsg(error);
    },
    removeUnits(unit) {
      for (let index = 0; index < this.notification.units.length; index++) {
        if (unit.source === "UNIT") {
          if (this.notification.units[index].items.selected.includes(unit.id)) {
            this.notification.units[index].items.unselected.push(unit.id);
            this.notification.units[index].items.selected =
              this.notification.units[index].items.selected.filter(
                (e) => e !== unit.id
              );

            this.selectedUnselectedFinalItems.unit.selected =
              this.selectedUnselectedFinalItems.unit.selected.filter(
                (e) => e !== unit.id
              );

            this.totalLookUpCount = this.totalLookUpCount - 1;
            this.removedSelectedItem = unit;
          }
        } else if (unit.source === "GROUP") {
          if (this.notification.units[index].items.selected.includes(unit.id)) {
            this.notification.units[index].items.unselected.push(unit.id);
            this.notification.units[index].items.selected =
              this.notification.units[index].items.selected.filter(
                (e) => e !== unit.id
              );
            this.selectedUnselectedFinalItems.group.selected =
              this.selectedUnselectedFinalItems.group.selected.filter(
                (e) => e !== unit.id
              );
            this.totalLookUpCount = this.totalLookUpCount - 1;
            this.removedSelectedItem = unit;
          }
        }
      }

      this.selectedUnitsData = this.selectedUnitsData.filter(
        (e) => e.id !== unit.id
      );
    },
    getUnitLookupCount(count) {
      this.totalLookUpCount = count;
    },
    async getNotification(id) {
      try {
        this.show = true;
        const me = this;

        let response = await new NotificationService().getNotification({ id });

        if (response && response.data) {
          this.show = false;
          this.alertId = response.data.alert.id;
          this.selectedUnitsData = response.data.units.list || [];
          this.unitsList = response.data.units.list || [];
          this.totalLookUpCount = response.data.units.pagination.total_records;
          this.selectedChannelList = response.data.preferences;
          this.unreadCount = response.data.unread_count;
          this.autoCreated = response.data.auto_created;
          this.allowEdit = response.data.allow_edit;
          // const arr = response.data.units.list.reduce(
          //   (groups, item) => ({
          //     ...groups,
          //     [item.source]: [...(groups[item.source] || []), item]
          //   }),
          //   {}notification.units
          // );

          // const unitObj = Object.keys(arr).map((e) => {
          //   return { source: e, items: { selected: arr[e] } };
          // });
          // this.selectedUnitsData.forEach((element) => {
          //   if (element && element.source === "GROUP") {
          //     this.selectedUnselectedItems.group.selected.push(element.id);
          //   } else if (element && element.source === "UNIT") {
          //     this.selectedUnselectedItems.unit.selected.push(element.id);
          //   }
          // });

          this.notification = {
            id: response.data.id,
            name: response.data.name,
            status: response.data.status,
            alert_id: response.data.alert.id,
            alert_name: response.data.alert.name,
            removed: response.data.alert.removed
          };
          this.alertStatus = response.data.alert.status;
          this.unitFun(
            { selected: this.selectedUnitsData, unSelected: [] },
            true
          );
          this.$route.meta.breadcrumb = [
            ...constants.NOTIFICATION_ROUTE_META_BREADCRUMB
          ];
          if (this.$route.name === "notification-edit") {
            this.$route.meta.breadcrumb.push(
              ...[
                {
                  to: {
                    name: "notification-view",
                    params: { id: response.data.id }
                  },
                  text: response.data.name
                },
                {
                  text: "Breadcrumb_Edit",
                  active: true
                }
              ]
            );
          } else {
            this.$route.meta.breadcrumb.push({
              text: response.data.name
            });
          }
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
    },
    unreadCountData(e) {
      this.unreadCount = e;
    },
    async getNotificationSelectedUnitsOrGroups() {
      try {
        if (this.$route.name === "notification-create") {
          return;
        }
        this.show = true;

        if (
          this.groupUnitTotalPage &&
          this.groupUnitTotalPage <=
            this.groupUnitPage * parseInt(this.groupUnitPageSize)
        ) {
          this.show = false;
          return;
        }

        let response =
          await new NotificationService().getNotificationSelectedUnitsOrGroups({
            id: this.notification.id,
            page: this.groupUnitPage + 1,
            page_size: parseInt(this.groupUnitPageSize)
          });

        if (response && response.data) {
          this.show = false;
          this.groupUnitPage = this.groupUnitPage + 1;
          if (response.data.list && response.data.list.length) {
            let data = [];
            for (let index = 0; index < response.data.list.length; index++) {
              const element = response.data.list[index];
              this.selectedUnselectedItems.group.unselected.filter((e) => e.i);
              const removedGroup =
                this.selectedUnselectedItems.group.unselected.find(
                  (o) => o.id === element.id
                );
              const removedUnit =
                this.selectedUnselectedItems.unit.unselected.find(
                  (o) => o.id === element.id
                );
              if (
                !removedGroup ||
                (!removedGroup.id && !removedUnit) ||
                !removedUnit.id
              ) {
                data.push(element);
                // this.totalLookUpCount = this.totalLookUpCount + 1;
              }
            }
            this.selectedUnitsData = [...this.selectedUnitsData, ...data];
            this.groupUnitTotalPage = response.data.pagination.total_records;

            this.unitFun(
              { selected: this.selectedUnitsData, unSelected: [] },
              true
            );
          }
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.refAlert.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    removeDuplicates(arr) {
      let unique = [];
      arr.forEach((element) => {
        if (!unique.includes(element)) {
          unique.push(element);
        }
      });
      return unique;
    },
    setSliderConfiguration() {
      this.sliderConfiguration = {
        breakpoints: {
          500: {
            spaceBetween: 20,
            slidesPerView: 1
          },
          768: {
            spaceBetween: 20,
            slidesPerView:
              this.$route.name === "notification-create" ||
              this.isNotificationAvailable
                ? 2
                : 1
          },
          992: {
            spaceBetween: 20,
            slidesPerView:
              this.$route.name === "notification-create" ||
              this.isNotificationAvailable
                ? 3
                : 1
          },

          1200: {
            spaceBetween: 50,
            slidesPerView:
              this.$route.name === "notification-create" ||
              this.isNotificationAvailable
                ? 3
                : 1
          }
        },
        slidesPerColumn: 1
      };
    }
  }
};
</script>
<style lang="scss">
.notification-create {
  position: relative;
  .mt-30 {
    margin-top: 30px;
    label {
      margin-bottom: 0px;
      padding-right: 10px;
    }
  }
  .unit-icon-card {
    // height: 45vh;
  }
  .alert-notification {
    max-height: 35vh;
    overflow-y: auto;
  }
  .create-notification {
  }
  .view-notification {
    .switch-alert,
    .add-name,
    .add-alert {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .add-alert {
    .form-group {
      margin-bottom: 0px;
    }
  }
  .col-md-6.view-notification {
    .mt-30 {
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
}
.view-notification-list-available {
  transition: 300ms ease all;
  margin-top: 10px;
}
.notificationavailable {
  flex: 0 0 0%;
  max-width: 0%;
  padding-right: 0rem;
  padding-left: 0rem;
  .view-notification-list {
    overflow: hidden;
  }
  .title-notification {
    overflow: hidden;
  }
}
.toggle-btn-cls {
  padding: 3px;
}
.default-transform {
  transition: 300ms ease all;
}
.fullsize {
  flex: 0 0 100%;
  max-width: 100%;
}
.view-notification-list {
  .ps-container {
    max-height: 90vh;
  }
}
.mt-11 {
  margin-top: 0.7rem;
}
.unread-count {
  position: relative;
  .badge {
    position: absolute;
    right: 0;
    margin-right: 17px;
    top: -6px;
  }
}
.wizard-back-unit {
  position: absolute;
  z-index: 9;
  right: 5px;
  top: 5px;
  cursor: pointer;
}
@media only screen and (max-width: 991px) {
  .create-alert-noti {
    .switch-alert {
      .mt-30 {
        margin-top: 15px;
        margin-bottom: 15px;
      }
    }
    .add-alert,
    .unit-search-comp {
      .input-group.mb-2 {
        margin-bottom: 0rem !important;
      }
    }
  }
}
.min-card-600 {
  margin-bottom: 15px;
}
.notification-edit-view {
  min-height: calc(100vh - 250px) !important;
  max-height: calc(100vh - 250px) !important;
  overflow-y: auto;
  overflow-x: hidden;
}
.view-notification-list-available {
  margin-top: 3px !important;
  height: calc(100vh - 230px) !important;
  height: calc(100vh - 230px) !important;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
<style lang="scss">
@media screen and (max-width: 991px) {
  .filter-card {
    display: none !important ;
  }
  .notification-edit-view {
    min-height: calc(100vh - 210px) !important;
    max-height: calc(100vh - 210px) !important;
  }
}
</style>
