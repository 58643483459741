<template>
  <div class="show-avtar">
    <div class="channel-bar mb-1" v-if="notificationActions.length > 1">
      <div class="d-flex">
        <b-form-checkbox
          v-model="selectedActions.all"
          :disabled="!isEditable"
          @change="(e) => handleCheckBox('all', null)"
          >{{ $t("EditRule.All") }}
        </b-form-checkbox>
      </div>
    </div>
    <div v-for="(action, index) in notificationActions" :key="index">
      <div class="channel-bar mb-1">
        <div class="d-flex">
          <b-form-checkbox
            :disabled="!isEditable"
            v-model="selectedActions[action].selected"
            @change="(e) => handleCheckBox(action, 'selected')"
          >
            {{ action }}
          </b-form-checkbox>
        </div>
        <b-collapse
          :id="action"
          :visible="selectedActions[action].selected"
          class="mt-2"
          v-model="selectedActions[action].selected"
        >
          <b-card class="border mb-0 d-flex justify-content-between cmr mr-00">
            <div class="d-flex justify-content-between mb-2-2">
              <div class="d-flex align-items-center">
                <b-form-checkbox
                  :disabled="!isEditable"
                  v-model="selectedActions[action].all"
                  @change="(e) => handleCheckBox(action, 'all')"
                >
                  {{ $t("Notification.SendAlertToAll") }}
                </b-form-checkbox>
              </div>
            </div>
            <div class="d-flex justify-content-between mb-2-2">
              <div class="d-flex align-items-center">
                <b-form-checkbox
                  :disabled="!isEditable"
                  v-model="selectedActions[action].team"
                  @change="(e) => handleCheckBox(action, 'team')"
                >
                  {{ $t("Notification.SendToTeams") }}
                </b-form-checkbox>
              </div>

              <div v-if="selectedActions[action].team">
                <b-avatar
                  :variant="people.variant"
                  v-for="people in selectedActions[action].team_data.showList"
                  :key="people.id"
                  :text="people.avtar_txt"
                  size="25"
                />

                <span @click="setTempTeam(action)" class="cursor-pointer">
                  <b-avatar
                    v-b-tooltip.hover.v-primary
                    :title="$t('Notification.ViewAndAddMoreUser')"
                    variant="primary"
                    size="25"
                    :text="`+${
                      selectedActions[action].team_data.lists &&
                      selectedActions[action].team_data.lists.length &&
                      selectedActions[action].team_data.lists.length - 4
                        ? selectedActions[action].team_data.lists.length - 4 > 0
                          ? selectedActions[action].team_data.lists.length - 4
                          : ''
                        : ''
                    }`"
                    @click="handleTeamModel(action)"
                  >
                  </b-avatar>
                </span>
              </div>
            </div>
            <div class="d-flex justify-content-between mb-2-2">
              <div class="d-flex align-items-center">
                <b-form-checkbox
                  :disabled="!isEditable"
                  v-model="selectedActions[action].people"
                  @change="(e) => handleCheckBox(action, 'people')"
                >
                  {{ $t("Notification.SendToSpecificPeoples") }}
                </b-form-checkbox>
              </div>
              <div v-if="selectedActions[action].people">
                <b-avatar
                  :variant="people.variant"
                  :src="people.avatar"
                  v-for="people in selectedActions[action].people_data.showList"
                  :key="people.id"
                  :text="people.avtar_txt"
                  size="25"
                />
                <span @click="setTempPeople(action)" class="cursor-pointer">
                  <b-avatar
                    v-b-tooltip.hover.v-primary
                    :title="$t('Notification.ViewAndAddMoreUser')"
                    variant="primary"
                    size="25"
                    :text="`+${
                      selectedActions[action].people_data.lists &&
                      selectedActions[action].people_data.lists.length &&
                      selectedActions[action].people_data.lists.length - 4 > 0
                        ? selectedActions[action].people_data.lists.length - 4
                        : ''
                    }`"
                  >
                  </b-avatar>
                </span>
              </div>
            </div>
          </b-card>
        </b-collapse>
      </div>
    </div>
    <b-modal
      id="team_people"
      class="custom-size-modal"
      size="md"
      centered
      no-close-on-esc
      no-close-on-backdrop
      :hide-footer="hideFooter"
    >
      <div class="d-flex justify-content-center">
        <b-card-title title-tag="h2" class="title font-weight-bold mb-0 title-line-height">
          <div>{{ $t("Notification.TeamPeoples") }}</div>
        </b-card-title>
      </div>
      <b-row class="mt-2 d-flex team_people">
        <b-col lg="6">
          <b-form-group
            :label="$t('Notification.Search_Name')"
            label-for="SearchTeamPeople"
          >
            <b-form-input
              id="SearchTeamPeople"
              :placeholder="$t('Notification.Search_Name')"
              v-model="teamPeoplesPagination.userSearch"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="table-responsive" v-if="isTeamPeopleLoadding">
        <b-skeleton-table
          v-if="isTeamPeopleLoadding"
          :rows="2"
          :columns="2"
          :table-props="{ bordered: true, striped: true }"
        />
      </div>
      <b-table
        :items="teamPeoples"
        :fields="fields"
        v-if="!isTeamPeopleLoadding"
        striped
        responsive
        style="border-radius: 5px"
        class="mt-1 specific-table-team-people"
      >
        <template #cell(Name)="row">
          <b-col md="12">
            <div class="d-flex align-items-center">
              <b-avatar
                :variant="row.item.variant"
                :text="row.item.avtar_txt"
                :src="row.item.avatar"
              />
              <strong class="padding-l-10">{{ row.item.name }}</strong>
            </div>
          </b-col>
        </template>
        <template #cell(role)="row">
          <b-col md="12">
            <strong>{{ row.item.role && row.item.role.name }}</strong>
          </b-col>
        </template>
      </b-table>
      <div class="demo-spacing-0 d-flex justify-content-end">
        <b-pagination
          v-model="teamPeoplesPagination.currentPage"
          :total-rows="teamPeoplesPagination.count"
          :per-page="teamPeoplesPagination.pageSize"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
      <div class="demo-spacing-0 d-flex justify-content-center">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          class="mt-1"
          @click="handleClose('team_people')"
        >
          {{ $t("Notification.Close") }}
        </b-button>
      </div>
    </b-modal>
    <b-modal
      id="specific_team"
      class="custom-size-modal"
      size="md"
      centered
      no-close-on-esc
      no-close-on-backdrop
      :hide-footer="hideFooter"
    >
      <div class="d-flex justify-content-center">
        <b-card-title title-tag="h2" class="title font-weight-bold mb-0 title-line-height">
          <div>Send {{ actionMode }} to Specific Team</div>
        </b-card-title>
      </div>
      <!-- basic -->
      <b-row class="mt-2 d-flex">
        <b-col lg="6">
          <b-form-group label="Search Team" label-for="SearchTeam">
            <b-form-input
              id="SearchTeam"
              placeholder="Search Team"
              v-model="teamsPagination.roleSearch"
              @input="handleTeamModel(actionMode)"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="table-responsive" v-if="isTeamLodding">
        <b-skeleton-table
          :rows="2"
          :columns="2"
          :table-props="{ bordered: true, striped: true }"
        />
      </div>
      <b-table
        :items="teams"
        v-if="!isTeamLodding"
        :fields="team_fields"
        striped
        responsive
        style="border-radius: 5px"
        class="mt-1 specific-table"
      >
        <template #cell(Name)="row">
          <b-form-checkbox
            class="pointer"
            :disabled="!isEditable"
            v-model="row.item.enable"
            @change="(e) => saveTeamPrefrences(row.item, actionMode)"
          >
            <b-col md="12">
              <b-avatar variant="success" :text="row.item.logo" size="18px" />
              <strong>{{ row.item.role_name }}</strong>
            </b-col>
          </b-form-checkbox>
        </template>
        <template #cell(people)="data">
          <b-avatar-group size="32px" class="avatar-min-size">
            <b-avatar
              class="pull-up"
              v-for="people in data.item.people.show_list"
              :src="people.avatar"
              :key="people.id"
              :variant="people.variant"
              :text="people.avtar_txt"
            />

            <b-avatar
              v-b-tooltip.hover.v-primary
              :title="$t('Notification.ClickHereToSeeAll')"
              variant="primary"
              class="pull-up"
              button
              @click="handleTeamPeopleModel(data.item.id)"
              :text="
                data.item.people &&
                data.item.people.pagination &&
                data.item.people.pagination.total_records &&
                data.item.people.pagination.total_records - 4 > 0
                  ? '+' + (data.item.people.pagination.total_records - 4)
                  : '+'
              "
            />
          </b-avatar-group>
        </template>
      </b-table>
      <div class="demo-spacing-0 d-flex justify-content-end">
        <b-pagination
          v-model="teamsPagination.currentPage"
          :total-rows="teamsPagination.count"
          :per-page="teamsPagination.pageSize"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
      <div class="demo-spacing-0 d-flex justify-content-center">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          class="mt-1 ml-15"
          v-if="isEditable"
          @click="handleTeamSave(actionMode)"
        >
          {{ $t("Notification.Save") }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          class="mt-1"
          @click="handleClose('specific_team')"
        >
          {{ $t("Notification.Close") }}
        </b-button>
      </div>
    </b-modal>
    <b-modal
      id="specific_people"
      class="custom-size-modal"
      size="md"
      ok-title="Send"
      centered
      no-close-on-esc
      no-close-on-backdrop
      :hide-footer="hideFooter"
    >
      <div class="d-flex justify-content-center">
        <b-card-title title-tag="h2" class="title font-weight-bold mb-0 title-line-height">
          <div class="mt-2">Send {{ actionMode }} to Specific People</div>
        </b-card-title>
      </div>
      <!-- basic -->
      <div class="size-h">
        <b-row class="mt-2 d-flex">
          <b-col lg="6">
            <b-form-group label="Search Name" label-for="SearchName">
              <b-form-input
                id="SearchName"
                placeholder="Search Name"
                v-model="peoplesPagination.userSearch"
              />
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <b-form-group label="Search role" label-for="SearchRole">
              <b-form-input
                id="SearchRole"
                placeholder="Search Role"
                v-model="peoplesPagination.roleSearch"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <div class="table-responsive" v-if="isPeoplesLodding">
          <b-skeleton-table
            v-if="isPeoplesLodding"
            :rows="2"
            :columns="2"
            :table-props="{ bordered: true, striped: true }"
          />
        </div>
        <b-table
          :items="peoples"
          :fields="fields"
          v-if="!isPeoplesLodding"
          striped
          responsive
          style="border-radius: 5px"
          class="mt-1 specific-table-people"
        >
          <template #cell(Name)="row">
            <b-form-checkbox
              class="pointer people-check"
              :disabled="!isEditable"
              v-model="row.item.enable"
              @change="(e) => savePeoplePrefrences(row.item, actionMode)"
            >
              <b-col md="12">
                <div class="d-flex align-items-center">
                  <b-avatar
                    :variant="row.item.variant"
                    :text="row.item.avtar_txt"
                    :src="row.item.avatar"
                  />
                  <strong class="padding-l-10">{{ row.item.name }}</strong>
                </div>
              </b-col>
            </b-form-checkbox>
          </template>
          <template #cell(role)="row">
            <strong class="ml-0">{{
              row.item.role && row.item.role.name
            }}</strong>
          </template>
        </b-table>
        <div class="demo-spacing-0 d-flex justify-content-end">
          <b-pagination
            v-model="peoplesPagination.currentPage"
            :total-rows="peoplesPagination.count"
            :per-page="peoplesPagination.pageSize"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </div>
      <div class="demo-spacing-0 d-flex justify-content-center">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          class="mt-1 ml-15"
          v-if="isEditable"
          @click="handlePeopleSave(actionMode)"
        >
          {{ $t("Notification.Save") }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          class="mt-1 ml-10 mr-25"
          @click="handleClose('specific_people')"
        >
          {{ $t("Notification.Close") }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import vBTooltip from "vue-ripple-directive";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import NotificationService from "@/libs/api/notification-service";
import AccountService from "@/libs/api/account-service";
import RuleService from "@/libs/api/rule-service";
import Loader from "@/layouts/components/Loader.vue";
import { required } from "@validations";
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormFile,
  BImg,
  BInputGroupAppend,
  BInputGroup,
  BPagination,
  BForm,
  BInputGroupPrepend,
  BFormCheckbox,
  BContainer,
  BTable,
  BMedia,
  BAvatar,
  BAvatarGroup,
  BCard,
  BFormRadio,
  VBToggle,
  BCollapse,
  BFormCheckboxGroup,
  BCardTitle,
  VBTooltip,
  BSkeletonTable
} from "bootstrap-vue";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BFormRadio,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BFormFile,
    BImg,
    BInputGroupAppend,
    BInputGroup,
    BForm,
    BInputGroupPrepend,
    BFormCheckbox,
    Map,
    BContainer,
    Loader,
    BTable,
    BMedia,
    BAvatar,
    BAvatarGroup,
    BCard,
    BCollapse,
    VBToggle,
    BFormCheckboxGroup,
    BCardTitle,
    BPagination,
    VBTooltip,
    BSkeletonTable
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
    "b-tooltip": VBTooltip
  },
  data() {
    return {
      onClose: false,
      handlePage: true,
      selectedRule: "",
      showLoading: false,
      userRuleId: null,
      deviceId: null,
      unitId: null,
      items: [],
      ismapLoad: false,
      mapCoordinates: null,
      isAddPolygon: false,
      mapComponentIndex: -1,
      rules: [],
      actionMode: "",
      hideFooter: true,
      selectedChannel: "",
      isTeamLodding: false,
      isPeoplesLodding: false,
      isTeamPeopleLoadding: false,
      selectedAction: "",
      fields: ["Name", { key: "role", label: "Role" }],
      teamPeoplesFields: ["Name", { key: "role", label: "Role" }],
      team_fields: [
        "Name",
        {
          key: "people",
          label: "Peoples",
          tdClass: "text-right group-avtar-right",
          thClass: "text-right "
        }
      ],
      // notificationActions: ["MAIL", "SMS", "PUSH", "COMMAND"],
      peoples: [],
      teams: [],
      teamPeoplesPagination: {
        pageSize: 5,
        count: 0,
        currentPage: 1,
        userSearch: ""
      },
      teamsPagination: {
        pageSize: 5,
        count: 0,
        currentPage: 1,
        roleSearch: ""
      },
      peoplesPagination: {
        pageSize: 5,
        count: 0,
        currentPage: 1,
        roleSearch: "",
        userSearch: ""
      },
      roleId: null,
      teamPeoples: [],
      selectedActions: {
        all: false,
        MAIL: {
          selected: false,
          all: false,
          team: false,
          people: false,
          team_data: {
            count: 0,
            lists: [],
            selected: [],
            unselected: [],
            selectedTemp: [],
            unselectedTemp: [],
            selectedFullTemp: []
          },
          people_data: {
            lists: [],
            count: 1,
            selected: [],
            unselected: [],
            selectedTemp: [],
            unselectedTemp: [],
            selectedFullTemp: []
          }
        },
        PUSH: {
          selected: false,
          all: false,
          team: false,
          people: false,
          team_data: {
            count: 0,
            lists: [],
            selected: [],
            unselected: [],
            selectedTemp: [],
            unselectedTemp: [],
            selectedFullTemp: []
          },
          people_data: {
            count: 0,
            lists: [],
            selected: [],
            unselected: [],
            selectedTemp: [],
            unselectedTemp: [],
            selectedFullTemp: []
          }
        },
        COMMAND: {
          selected: false,
          all: false,
          team: false,
          people: false,
          team_data: {
            count: 0,
            lists: [],
            selected: [],
            unselected: [],
            selectedTemp: [],
            unselectedTemp: [],
            selectedFullTemp: []
          },
          people_data: {
            count: 0,
            lists: [],
            selected: [],
            unselected: [],
            selectedTemp: [],
            unselectedTemp: [],
            selectedFullTemp: []
          }
        },
        SMS: {
          selected: false,
          all: false,
          team: false,
          people: false,
          team_data: {
            count: 0,
            lists: [],
            selected: [],
            unselected: [],
            selectedTemp: [],
            unselectedTemp: [],
            selectedFullTemp: []
          },
          people_data: {
            count: 0,
            lists: [],
            selected: [],
            unselected: [],
            selectedTemp: [],
            unselectedTemp: [],
            selectedFullTemp: []
          }
        }
      },
      teamsData: { selected: [], unselected: [] },
      usersData: { selected: [], unselected: [] },
      required
    };
  },
  watch: {
    selectedChannelList() {
      this.getDeviceRuleDetails();
    },
    "peoplesPagination.userSearch"() {
      this.handlePeopleModel(this.actionMode, true);
    },
    "peoplesPagination.roleSearch"() {
      this.handlePeopleModel(this.actionMode, true);
    },
    async "teamPeoplesPagination.userSearch"() {
      this.onClose = true;
      await this.handleTeamPeopleModel(this.roleId);
      this.onClose = false;
    },
    "teamPeoplesPagination.currentPage"() {
      this.handleTeamPeopleModel(this.roleId);
    },
    async "teamsPagination.roleSearch"() {
      this.onClose = true;
      await this.handleTeamModel(this.actionMode);
      this.onClose = false;
    },
    "teamsPagination.currentPage"() {
      this.handleTeamModel(this.actionMode);
    },
    "peoplesPagination.currentPage"() {
      this.handlePeopleModel(this.actionMode, true);
    },
    isClear() {
      if (this.$route.name === "notification-create") {
        this.selectedActions = {
          all: false,
          MAIL: {
            selected: false,
            all: false,
            team: false,
            people: false,
            team_data: {
              count: 0,
              lists: [],
              selected: [],
              unselected: [],
              selectedTemp: [],
              unselectedTemp: [],
              selectedFullTemp: []
            },
            people_data: {
              lists: [],
              count: 1,
              selected: [],
              unselected: [],
              selectedTemp: [],
              unselectedTemp: [],
              selectedFullTemp: []
            }
          },
          PUSH: {
            selected: false,
            all: false,
            team: false,
            people: false,
            team_data: {
              count: 0,
              lists: [],
              selected: [],
              unselected: [],
              selectedTemp: [],
              unselectedTemp: [],
              selectedFullTemp: []
            },
            people_data: {
              count: 0,
              lists: [],
              selected: [],
              unselected: [],
              selectedTemp: [],
              unselectedTemp: [],
              selectedFullTemp: []
            }
          },
          COMMAND: {
            selected: false,
            all: false,
            team: false,
            people: false,
            team_data: {
              count: 0,
              lists: [],
              selected: [],
              unselected: [],
              selectedTemp: [],
              unselectedTemp: [],
              selectedFullTemp: []
            },
            people_data: {
              count: 0,
              lists: [],
              selected: [],
              unselected: [],
              selectedTemp: [],
              unselectedTemp: [],
              selectedFullTemp: []
            }
          },
          SMS: {
            selected: false,
            all: false,
            team: false,
            people: false,
            team_data: {
              count: 0,
              lists: [],
              selected: [],
              unselected: [],
              selectedTemp: [],
              unselectedTemp: [],
              selectedFullTemp: []
            },
            people_data: {
              count: 0,
              lists: [],
              selected: [],
              unselected: [],
              selectedTemp: [],
              unselectedTemp: [],
              selectedFullTemp: []
            }
          }
        };
      }
    }
  },
  mounted() {
    this.deviceId = this.$route.query.deviceId;
    this.userRuleId = this.$route.query.id;
    this.unitId = this.$route.query.unitId;
    this.getDeviceRuleDetails();
  },
  props: [
    "notificationActions",
    "getNotificationChannel",
    "selectedChannelList",
    "isEditable",
    "notificationData",
    "isClear"
  ],
  methods: {
    handleClose(id) {
      const resetPagination = (pagination) => {
        pagination["count"] = 0;
        pagination["currentPage"] = 1;
        pagination["roleSearch"] = "";
        pagination["userSearch"] = "";
      };

      const filterSelected = (action, type) => {
        const data = this.selectedActions[action][`${type}_data`];
        data.selectedTemp = data.selectedTemp.filter((e) =>
          data.selected.includes(e)
        );
        data.selectedFullTemp = data.selectedFullTemp.filter((e) =>
          data.selected.includes(e.id)
        );
      };

      if (id == "specific_team") {
        this.teamsPagination["roleSearch"] = " ";
        this.teamsPagination["roleSearch"] = "";
        // this.getSelectedTeams(this.actionMode);
        resetPagination(this.teamPeoplesPagination);
        ["MAIL", "SMS", "PUSH"].forEach((action) =>
          filterSelected(action, "team")
        );
      } else if (id == "specific_people") {
        this.getSelectedPeoples(this.actionMode);
        resetPagination(this.peoplesPagination);
        ["MAIL", "SMS", "PUSH"].forEach((action) =>
          filterSelected(action, "people")
        );
      } else if (id == "team_people") {
        this.teamPeoplesPagination["userSearch"] = " ";
        resetPagination(this.teamPeoplesPagination);
      }

      this.$bvModal.hide(id);
    },
    async saveTeamPrefrences(row, key) {
      if (row.enable && row.id) {
        if (this.selectedActions[key].team_data.selectedTemp.includes(row.id)) {
          return;
        }
        this.selectedActions[key].team_data.selectedTemp.push(row.id);
        this.selectedActions[key].team_data.selectedFullTemp.push(row);
        this.selectedActions[key].team_data.unselectedTemp =
          this.selectedActions[key].team_data.unselectedTemp.filter(
            (e) => e !== row.id
          );
      } else if (!row.enable && row.id) {
        this.selectedActions[key].team_data.selectedTemp = this.selectedActions[
          key
        ].team_data.selectedTemp.filter((e) => e !== row.id);
        this.selectedActions[key].team_data.selectedFullTemp =
          this.selectedActions[key].team_data.selectedFullTemp.filter(
            (e) => e.id !== row.id
          );
        this.selectedActions[key].team_data.unselectedTemp.push(row.id);
      }

      this.selectedActions[key].relatedData = this.teamsData;
    },
    async savePeoplePrefrences(row, key) {
      if (row.enable && row.id) {
        if (
          this.selectedActions[key].people_data.selectedTemp.includes(row.id)
        ) {
          return;
        }
        this.selectedActions[key].people_data.selectedTemp.push(row.id);
        this.selectedActions[key].people_data.selectedFullTemp.push(row);
        this.selectedActions[key].people_data.unselectedTemp =
          this.selectedActions[key].people_data.unselectedTemp.filter(
            (e) => e !== row.id
          );
      } else if (!row.enable && row.id) {
        this.selectedActions[key].people_data.unselectedTemp.push(row.id);
        this.selectedActions[key].people_data.selectedTemp =
          this.selectedActions[key].people_data.selectedTemp.filter(
            (e) => e !== row.id
          );
        this.selectedActions[key].people_data.selectedFullTemp =
          this.selectedActions[key].people_data.selectedFullTemp.filter(
            (e) => e.id !== row.id
          );
      }
    },
    async handlePeopleSave(key) {
      this.selectedActions[key].people_data.selected = [
        ...new Set(this.selectedActions[key].people_data.selectedTemp)
      ];
      this.selectedActions[key].people_data.unselected = [
        ...new Set(this.selectedActions[key].people_data.unselectedTemp)
      ];
      this.selectedActions[key].people_data.lists =
        this.selectedActions[key].people_data.selectedFullTemp || [];

      this.selectedActions[key].people_data.lists =
        this.selectedActions[key].people_data.selectedFullTemp || [];
      this.selectedActions[key].people_data.selected =
        this.selectedActions[key].people_data.selectedFullTemp.map(
          (e) => e.id
        ) || [];
      this.selectedActions[key].people_data.showList = this.formattedItems(
        this.selectedActions[key].people_data.selectedFullTemp.slice(0, 4)
      );
      await this.getNotificationChannel(this.selectedActions);
      this.selectedActions = this.selectedActions;
      this.handleClose("specific_people");
    },
    async handleTeamSave(key) {
      this.selectedActions[key].team_data.selected = [
        ...new Set(this.selectedActions[key].team_data.selectedTemp)
      ];
      this.selectedActions[key].team_data.unselected = [
        ...new Set(this.selectedActions[key].team_data.unselectedTemp)
      ];

      this.selectedActions[key].team_data.lists =
        this.selectedActions[key].team_data.selectedFullTemp || [];
      this.selectedActions[key].team_data.selected =
        this.selectedActions[key].team_data.selectedFullTemp.map((e) => e.id) ||
        [];
      this.selectedActions[key].team_data.showList = this.formattedItems(
        this.selectedActions[key].team_data.selectedFullTemp.slice(0, 4)
      );
      await this.getNotificationChannel(this.selectedActions);
      this.selectedActions = this.selectedActions;
      this.handleClose("specific_team");
    },
    setTempPeople(action, restrictPopup) {
      this.selectedActions[action].people_data.selectedTemp =
        [...this.selectedActions[action].people_data.selected] || [];
      this.handlePeopleModel(action, restrictPopup);
    },
    async handlePeopleModel(action, restrictPopup) {
      this.actionMode = action;
      try {
        const me = this;
        this.isPeoplesLodding = true;
        const filters = [];
        let notificationFilter = {};
        if (this.peoplesPagination.userSearch) {
          filters.push({
            field: "name",
            value: this.peoplesPagination.userSearch.trim(),
            operator: "ilike"
          });
        }
        if (this.peoplesPagination.roleSearch) {
          filters.push({
            field: "role",
            value: this.peoplesPagination.roleSearch.trim(),
            operator: "ilike"
          });
        }
        if (this.notificationData && this.notificationData.id) {
          notificationFilter = { notification_id: this.notificationData.id };
        }
        let response = await new NotificationService().getUsers({
          page: this.peoplesPagination.currentPage,
          page_size: this.peoplesPagination.pageSize,
          channel: action,
          filters,
          ...notificationFilter
        });
        if (response && response.data) {
          this.peoples = response.data.list.map((u) => {
            u.avtar_txt = this.userAvatarTxt(u.name);
            u.variant = this.getRandomBgColor();
            const peopleSelected =
              this.selectedActions[action].people_data.selectedTemp || [];
            if (peopleSelected.includes(u.id)) {
              u.enable = true;
            }
            return u;
          });
          this.peoplesPagination["count"] =
            response.data.pagination.total_records;
          this.peoplesPagination["currentPage"] = response.data.pagination.page;
          if (!restrictPopup) {
            this.$bvModal.show("specific_people");
          }
          this.isPeoplesLodding = false;
        } else {
          this.isPeoplesLodding = false;
        }
      } catch (err) {
        this.isPeoplesLodding = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async handleTeamPeopleModel(roleId) {
      this.roleId = roleId;

      const filters = this.teamPeoplesPagination.userSearch
        ? [
            {
              field: "name",
              value: this.teamPeoplesPagination.userSearch,
              operator: "ilike"
            }
          ]
        : [];
      try {
        this.isTeamPeopleLoadding = true;
        let response = await new AccountService().getTeamPeoples({
          role_id: roleId,
          filters,
          page: this.teamPeoplesPagination.currentPage,
          page_size: this.teamPeoplesPagination.pageSize
        });
        if (response && response.data) {
          this.isTeamPeopleLoadding = false;
          this.teamPeoples = response.data.list;
          this.teamPeoples = this.teamPeoples.map((prop) => {
            prop.name = prop.name || "";
            prop.avtar_txt = this.userAvatarTxt(prop.name);
            prop.variant = this.getRandomBgColor();
            return prop;
          });
          this.teamPeoplesPagination["count"] =
            response.data.pagination.total_records;
          this.teamPeoplesPagination["currentPage"] =
            response.data.pagination.page;
          if (!this.onClose) {
            this.$bvModal.show("team_people");
          } else {
            this.onClose = false;
          }
        } else {
          this.isTeamPeopleLoadding = false;
        }
        this.onClose = false;
      } catch (err) {
        this.isTeamPeopleLoadding = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    setTempTeam(action) {
      this.selectedActions[action].team_data.selectedTemp =
        [...this.selectedActions[action].team_data.selected] || [];
      this.handleTeamModel(action);
    },
    async handleTeamModel(action) {
      this.actionMode = action;
      let notificationFilter = {};

      const filters = this.teamsPagination.roleSearch
        ? [
            {
              field: "role_name",
              value: this.teamsPagination.roleSearch,
              operator: "ilike"
            }
          ]
        : [];

      if (this.notificationData && this.notificationData.id) {
        notificationFilter = { notification_id: this.notificationData.id };
      }
      try {
        // this.showLoading = true;
        this.isTeamLodding = true;

        let response = await new NotificationService().getTeams({
          pagination: {
            page: this.teamsPagination.currentPage,
            page_size: this.peoplesPagination.pageSize
          },
          ...notificationFilter,
          filters,
          channel: action
        });

        if (response && response.data) {
          this.isTeamLodding = false;
          this.teams = response.data.list.map((u) => {
            const teamSelected =
              this.selectedActions[action].team_data.selectedTemp || [];
            if (teamSelected.includes(u.id)) {
              u.enable = true;
            }
            return u;
          });

          for (let team of this.teams) {
            team.people.list = team.people.list.map((prop) => {
              prop.name = prop.name || "";
              prop.avtar_txt = this.userAvatarTxt(prop.name);
              prop.variant = this.getRandomBgColor();

              return prop;
            });
            const totalRecords =
              (team &&
                team.people &&
                team.people.pagination &&
                team.people.pagination.total_records) ||
              0;
            team.totalPeople = totalRecords > 4 ? totalRecords - 4 : "";
            // if (team.people.count > 4) {
            //   team.people.list.push({
            //     name: `+${team.people.count - 4}`
            //   });
            // }
            if (team.people.list && team.people.list.length >= 4) {
              team.people.show_list = team.people.list.slice(0, 4);
            } else {
              team.people.show_list = team.people.list;
            }
          }

          this.teamsPagination["count"] =
            response.data.pagination.total_records;
          this.teamsPagination["currentPage"] = response.data.pagination.page;
          if (!this.onClose) {
            this.$bvModal.show("specific_team");
          } else {
            this.onClose = false;
          }
        } else {
          this.isTeamLodding = false;
        }
        this.onClose = false;
        //  this.showLoading = false;
      } catch (err) {
        this.isTeamLodding = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async handleCheckBox(action, property) {
      this.selectedChannel = action;
      this.selectedAction = property;
      const me = this;
      if (action == "all" && this.selectedActions["all"] == true) {
        for (let item of this.notificationActions) {
          this.selectedActions[item]["selected"] = true;
          this.selectedActions[item]["all"] = true;
          this.selectedActions[item]["team"] = false;
          this.selectedActions[item]["people"] = false;

          this.selectedActions[item].team_data.lists = [];
          this.selectedActions[item].team_data.showList = [];
          this.selectedActions[item].people_data.lists = [];
          this.selectedActions[item].people_data.showList = [];
        }
      } else if (action == "all" && this.selectedActions["all"] == false) {
        for (let item of this.notificationActions) {
          this.selectedActions[item]["selected"] = false;
          for (let param of ["all", "team", "people"]) {
            this.selectedActions[item][param] = false;
          }
        }
      } else if (
        this.notificationActions.indexOf(action) >= 0 &&
        ["all", "team", "people"].indexOf(property) >= 0
      ) {
        let obj = {
          all: ["team", "people"],
          people: ["all", "team"],
          team: ["all", "people"]
        };
        if (this.selectedActions[action][property] == true) {
          this.selectedActions[action]["selected"] = true;
          for (let param of obj[property]) {
            this.selectedActions[action][param] = false;
          }
        } else if (this.selectedActions[action][property] == false) {
          this.selectedActions[action]["selected"] = false;
        }
      } else if (
        this.notificationActions.indexOf(action) >= 0 &&
        property == "selected"
      ) {
        if (this.selectedActions[action][property] == true) {
          if (
            this.selectedActions[action]["team"] == false &&
            this.selectedActions[action]["people"] == false
          ) {
            this.selectedActions[action]["all"] = true;
          }
        } else {
          for (let param of ["all", "team", "people"]) {
            this.selectedActions[action][param] = false;
          }
        }
      }
      let selectedCount = 0;
      for (let item of this.notificationActions) {
        if (this.selectedActions[item]["selected"] == true) {
          selectedCount++;
          if (
            this.selectedActions[item]["team"] == false &&
            this.selectedActions[item]["people"] == false
          ) {
            this.selectedActions[item]["all"] = true;
          }
        }
        if (selectedCount == me.notificationActions.length) {
          this.selectedActions.all = true;
        } else {
          this.selectedActions.all = false;
        }
      }
      this.getNotificationChannel(this.selectedActions);
    },

    async getSelectedPeoples(action) {
      try {
        this.showLoading = true;
        // let response = await new RuleService().getUsers({
        //   page: 1,
        //   page_size: 5,
        //   channel: action,
        //   selected_peoples: true,
        //   user_device_action_id: this.userRuleId
        // });
        // this.updateSelectedActions(response, action, "people", "user_name");
        this.showLoading = false;
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    updateSelectedActions(response, action, param, key) {
      if (response && response.data) {
        this.selectedActions[action][`${param}_data`]["lists"] =
          response.data.lists;
        this.selectedActions[action][`${param}_data`]["count"] =
          response.data.count;
        this.updateSelectedTeamAndPeopledata(action, param, key);
      }
    },
    async getSelectedTeams(action) {
      try {
        this.showLoading = true;
        let response = await new NotificationService().getTeams({
          page: 1,
          page_size: 5,
          selected_teams: true,
          channel: action,
          user_device_action_id: this.userRuleId
        });
        this.updateSelectedActions(response, action, "team", "team_name");
        this.showLoading = false;
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    updateSelectedTeamAndPeopledata(action, param, key) {
      this.selectedActions[action][`${param}_data`].lists &&
        this.selectedActions[action][`${param}_data`].lists.map(
          (prop, index) => {
            this.selectedActions[action][`${param}_data`].lists[index] = prop[
              key
            ].split(" ")[0]
              ? prop[key].split(" ").length > 1
                ? prop[key].split(" ")[0][0] + prop[key].split(" ")[1][0]
                : prop[key].split(" ")[0][0]
              : "";
          }
        );
      if (this.selectedActions[action][`${param}_data`].count > 4) {
        this.selectedActions[action][`${param}_data`].lists.push(
          `+${this.selectedActions[action][`${param}_data`].count - 4}`
        );
      }
    },
    getMax(data) {
      const responseData = data || [];
      return responseData.slice(0, 4);
    },
    async getDeviceRuleDetails() {
      const data = this.selectedChannelList;
      const me = this;
      for (let item of data) {
        let option = item.option && item.option.toLowerCase();
        if (option === "specific_user") {
          option = "people";
        }

        this.selectedActions[item.channel]["selected"] = true;

        me.selectedActions[item.channel][option] = true;

        if (option === "team") {
          me.selectedActions[item.channel].team_data.lists =
            (item.items && item.items.list) || [];
          this.selectedActions[item.channel].team_data.selectedFullTemp =
            (item.items && item.items.list) || [];
          me.selectedActions[item.channel].team_data.selected =
            (item.items &&
              item.items.list &&
              item.items.list.map((e) => e.id)) ||
            [];
          me.selectedActions[item.channel].team_data.showList =
            this.formattedItems(item.items && item.items.list.slice(0, 4));
        } else if (option === "people") {
          me.selectedActions[item.channel].people_data.lists =
            (item.items && item.items.list) || [];
          this.selectedActions[item.channel].people_data.selectedFullTemp =
            (item.items && item.items.list) || [];
          me.selectedActions[item.channel].people_data.selected =
            (item.items &&
              item.items.list &&
              item.items.list.map((e) => e.id)) ||
            [];
          const displayPeople =
            (item.items && item.items.list && item.items.list.slice(0, 4)) ||
            [];
          me.selectedActions[item.channel].people_data.showList =
            this.formattedItems(displayPeople);
        }
      }
      this.getNotificationChannel(this.selectedActions);
    },

    formattedItems(items) {
      const formatted =
        (items &&
          items.map((u) => {
            u.variant = this.getRandomBgColor();
            u.avtar_txt = this.userAvatarTxt(u.name || u.role_name);
            return u;
          })) ||
        items;
      return formatted;
    }
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "./index.scss";
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.channel-bar {
  padding: 20px;
  background: var(--white);
  box-shadow: 0px 0px 8px $rgb_black_6;
  border-radius: 4px;
}
.mr-25 {
  position: relative;
  margin-right: 25px;
}
.mb-2-2 {
  min-height: 45px;
}
.b-avatar {
  min-width: 32px;
  min-height: 32px;
}
.mr-00 {
  margin-left: 0;
  margin-right: 0;
}
.specific-table {
  .custom-checkbox .custom-control-label::before,
  .custom-checkbox .custom-control-label::after {
    top: 6px;
  }
}
@media screen and (max-width: 991px) {
  .show-avtar{
    .b-avatar.rounded-circle{
          margin-right: -15px;
    }
    .b-avatar.rounded-circle:last-child{
      margin-right: 0px;
    }
  }
  .size-h {
    max-height: 45vh;
  }
  .specific-table.table-responsive {
    height: 31vh;
  }
  .title-line-height{
    line-height: 35px;
  }
}
</style>
