<template>
  <div class="height-100p">
    <div class="view-notification-list" v-if="items && items.length">
      <div class="d-flex justify-content-between title-notification">
        <h5>{{ $t("Notification.Notifications") }}</h5>
      </div>
      <vue-perfect-scrollbar ref="scrollContainer" @ps-scroll-y="checkScroll">
        <div
          v-for="(item, index) in items"
          :key="index"
          class="view-notification-repeater"
          :class="!item.read ? 'light-bg-primary' : ''"
        >
          <b-row>
            <div class="col-md-4 d-flex align-items-center">
              <b-media class="">
                <template #aside>
                  <b-avatar
                    size="50"
                    :src="item.unit && item.unit.icon"
                    :text="item.unit && item.unit.avtar_txt"
                    :variant="
                      item.unit && item.unit.name
                        ? item.unit.variant
                        : 'primary'
                    "
                  />
                </template>
                <h6 class="mb-0 text-overflow-ellipsis">
                  {{ item.unit && item.unit.name }}
                </h6>
                <small class="text-primary text-overflow-ellipsis">
                  <b-link
                    class="curserPointer"
                    target="_blank"
                    :to="{
                      name: 'alert-view',
                      params: { id: notification.alert_id }
                    }"
                    >{{ notification && notification.alert_name }}
                  </b-link>
                </small>
              </b-media>
            </div>
            <div class="col-md-8">
              <div class="d-flex justify-content-end align-items-center">
                <span class="text-right time-count">{{
                  updateReportTime(item.time)
                }}</span>
                <b-avatar-group size="32px" class="avatar-min-size">
                  <b-avatar
                    class="pull-up"
                    button
                    v-for="user in item.users.show_list"
                    :key="user.id"
                    :src="user.avatar"
                    :variant="user.variant"
                    :text="user.avtar_txt"
                    @click="showActivityDetails(item)"
                  />
                  <b-avatar
                    class="pull-up"
                    @click="showActivityDetails(item)"
                    variant="primary"
                    button
                    v-if="
                      item.users &&
                      item.users.pagination &&
                      item.users.pagination.total_records -
                        item.users.show_list.length
                    "
                    :text="`+${
                      item.users &&
                      item.users.pagination &&
                      item.users.pagination.total_records -
                        item.users.show_list.length
                    }`"
                  />
                </b-avatar-group>
              </div>
              <div class="d-flex justify-content-end align-items-center mrt-10">
                <Badge
                  :tags="item.channels"
                  :showTextInBadge="Number(8)"
                  :event="item"
                  :maxTagShow="Number(4)"
                ></Badge>
              </div>
            </div>
          </b-row>
        </div>
      </vue-perfect-scrollbar>

      <NotificationSendList
        :selectedLog="selectedLog"
        :notification="notification"
      />
    </div>
    <div v-if="!items || !items.length">
      <span class="notification-empty-log">
        <div class="d-flex justify-content-between title-notification">
          <h3>{{ $t("Notification.Notifications") }}</h3>
        </div>
        <span
          ><h5>{{ $t("Notification.NotificationLogEmpty") }}</h5></span
        >
      </span>
    </div>
  </div>
</template>

<script>
import {
  BCardBody,
  BCardText,
  BMedia,
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BAvatarGroup,
  BLink
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import NotificationService from "@/libs/api/notification-service";
import NotificationSendList from "@/views/Notification/NotificationSendList.vue";
import Badge from "@/layouts/components/Badge.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import moment from "moment";
import constants from "@/utils/constants";
export default {
  components: {
    BCardBody,
    BCardText,
    BRow,
    BCol,
    BBadge,
    BMedia,
    BAvatar,
    BAvatarGroup,
    NotificationSendList,
    BLink,
    Badge,
    VuePerfectScrollbar
  },
  setup() {},
  data() {
    return {
      removedNotificationLogData: {},
      selectedLog: "",
      show: false,
      filter: {},
      items: [],
      currentPage: 1,
      perPage: 10,
      notificationLogName: "",
      isLoading: false
    };
  },
  props: ["notification", "unreadCountData", "hideLogScreen"],
  mounted() {
    this.getNotificationActivity();
  },
  methods: {
    async getNotificationActivity() {
      try {
        this.show = true;

        if (!this.filter || !this.filter.value) {
          this.filter = {};
        }
        let requestData = {
          page: this.currentPage,
          notification_id: this.notification.id,
          page_size: parseInt(this.perPage),
          filters:
            this.filter && Object.keys(this.filter).length > 0
              ? [this.filter]
              : []
        };

        let response = await new NotificationService().getNotificationActivity(
          requestData
        );

        if (response && response.data) {
          this.show = false;
          const logData = response.data.list || [];
          if (logData && logData.length) {
            this.items.push(...logData);
          }

          this.items.forEach((element) => {
            element.users.list = element.users.list.map((u) => {
              u.variant = this.getRandomBgColor();
              u.avtar_txt = this.userAvatarTxt(u.name);
              return u;
            });
            element.unit.variant = this.getRandomBgColor();
            element.unit.avtar_txt = this.userAvatarTxt(element.unit.name);

            if (element.users.list && element.users.list.length >= 3) {
              element.users.show_list = element.users.list.slice(0, 2);
            } else {
              element.users.show_list = element.users.list;
            }
          });

          this.totalNotificationLog =
            (response.data.pagination &&
              response.data.pagination.total_records) ||
            0;
          this.unreadCountData(response.data.unread_count);
          const isHideLogScreen = this.totalNotificationLog > 0 ? true : false;
          this.hideLogScreen(isHideLogScreen);
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
    },
    DateTimeConvert(date) {
      let formattedDate = date ? date : new Date();
      return this.formattedDateTime(formattedDate, constants.DATE_TIME_FORMAT);
    },
    checkScroll() {
      const container = this.$refs.scrollContainer.$el;
      const scrollPosition = container.scrollTop + container.offsetHeight;
      const scrollHeight = container.scrollHeight;

      if (scrollPosition >= scrollHeight && !this.isLoading) {
        this.currentPage = this.currentPage + 1;
        this.getNotificationActivity();
      }
    },
    updateReportTime(time) {
      if (!time) {
        return "";
      }
      const duration = moment.duration(moment().diff(time));
      let timeData = "";
      if (duration.asMinutes() < 59) {
        timeData = moment(time).fromNow();
      } else if (duration.asMinutes() < 1440) {
        timeData = moment(time).calendar();
      } else if (duration.asMinutes() < 2880) {
        timeData = this.DateTimeConvert(time);
        // moment(time).format("dddd, h:mm A");
      } else {
        timeData = this.DateTimeConvert(time);
        // moment(time).format("DD/MM/YYYY hh:mm A");
      }
      return timeData;
    },
    showActivityDetails(log) {
      this.selectedLog = log.event_id;
      this.notificationLogName = log.unit.name;
      this.$bvModal.show("view-all-user-list");
    }
  }
};
</script>

<style lang="scss" scoped>
.view-notification-list {
  box-shadow: 0px 4px 12px rgba(34, 41, 47, 0.1);
  border-radius: 8px;
  background: #fff;
  .view-notification-repeater {
    padding: 12px 15px;
    border-bottom: 1px solid #404656;
    &:last-child {
      border-bottom: none;
    }
    .media {
      width: 100%;
      .media-body {
        width: calc(100% - 65px);
        .text-overflow-ellipsis {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          max-width: 99%;
          display: block;
        }
      }
    }
  }
  .time-count {
    padding-right: 10px;
  }
  .font-light {
    font-weight: 500;
    padding: 6px 15px;
    text-transform: uppercase;
    margin-left: 5px;
  }
  .title-notification {
    padding: 15px 15px 6px 15px;
  }
  .mrt-10 {
    margin-top: 10px;
  }
  .avatar-min-size {
    .b-avatar {
      min-width: 32px;
      min-height: 32px;
    }
  }
}
.notificationavailable {
  .notification-empty-log {
    display: none;
  }
}
.height-100p {
  height: 100%;
  .view-notification-list {
    height: 97%;
  }
}
</style>
