<template>
  <div>
    <swiper
      class="swiper-responsive-breakpoints px-4 py-2 swiper-centered-slides"
      :options="swiperOptions"
    >
      <swiper-slide
        v-for="(product, index) in selectedUnitsData"
        :key="index"
        class="rounded swiper-shadow custom-card u-lr-card"
      >
        <div
          class="testslider"
          v-if="product.source && product.source.toLowerCase() == 'group'"
        >
          <div class="d-flex justify-content-between mr-bt-12">
            <span>
              <span class="mr-1">{{ index + 1 }} / {{ totalLookUpCount }}</span>
              <b-badge variant="primary" pill style="">
                {{ formattedDescriptions(product.name, 20) }}
              </b-badge>
            </span>
            <div class="d-flex justify-content-end">
              <feather-icon
                v-if="isEditable"
                icon="Trash2Icon"
                class="text-danger action-icon"
                size="18"
                style="position: relative; cursor: pointer"
                @click="removeUnits(product)"
              />
            </div>
          </div>
          <div class="d-flex mr-bt-15 min-height-18">
            {{
              product.description
                ? formattedDescriptions(product.description, 27)
                : ""
            }}
          </div>

          <div class="d-flex mobile-wrap margin-badge">
            <div
              class="side-custom"
              v-for="(value, key, index) in product.unit_stats"
              :key="index"
              @click="SupportedUnits(value, key, product)"
              v-show="!isReport"
            >
              <b-badge variant="primary" pill style="">
                {{ value }}
              </b-badge>
              <span style="text-transform: capitalize; padding: 0 5px">{{
                key
              }}</span>
            </div>
          </div>
        </div>
        <div v-if="product.source && product.source.toLowerCase() == 'unit'">
          <div class="d-flex justify-content-between mb-1">
            <span>{{ index + 1 }} / {{ totalLookUpCount }}</span>
            <div class="d-flex justify-content-end">
              <feather-icon
                icon="Trash2Icon"
                v-if="isEditable"
                class="text-danger action-icon"
                size="18"
                @click="removeUnits(product)"
                style="position: relative; cursor: pointer"
              />
            </div>
          </div>
          <b-media class="align-item-center">
            <template #aside>
              <b-avatar
                size="50"
                :src="product.icon"
                text="PR"
                variant="light-primary"
              />
            </template>

            <h6 class="mb-0">
              <b-link
                target="_blank"
                class="curserPointer"
                :to="{
                  name: 'view-unit',
                  params: { id: product.id }
                }"
                >{{ product.name }}
              </b-link>
            </h6>
            <small class="text-muted">{{
              product.account && product.account.name
                ? product.account.name
                : ""
            }}</small>
          </b-media>
          <div class="d-flex justify-content-end">
            <b-badge
              :variant="product.supported ? 'light-success' : 'danger'"
              pill
              style=""
            >
              {{
                product.supported
                  ? $t("Notification.Active")
                  : $t("Notification.UnSupported")
              }}
            </b-badge>
          </div>
        </div>
      </swiper-slide>
      <div slot="button-next" class="swiper-button-next" />
      <div slot="button-prev" class="swiper-button-prev" />
    </swiper>
    <SupportedUnitList
      :source="unitKey"
      :alertId="alertId"
      :groupId="groupId"
    />
  </div>
</template>

<script>
import {
  BCardBody,
  BCardText,
  BImg,
  BMedia,
  BAvatar,
  BLink,
  BBadge
} from "bootstrap-vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import SupportedUnitList from "@/views/Notification/SupportedUnitList.vue";
export default {
  components: {
    BCardBody,
    BCardText,
    BImg,
    BLink,
    BBadge,
    Swiper,
    BMedia,
    BAvatar,
    SwiperSlide,
    SupportedUnitList
  },
  setup() {},
  data() {
    return {
      selectedGroup: {},
      unitKey: "ALL",
      groupId: "",
      swiperOptions: {
        spaceBetween: 20,
        breakpoints: {
          500: {
            spaceBetween: 10,
            slidesPerView: 1
          },
          768: {
            spaceBetween: 10,
            slidesPerView: 2
          },
          992: {
            spaceBetween: 20,
            slidesPerView: 3
          },

          1200: {
            spaceBetween: 50,
            slidesPerView: 3
          }
        },
        slidesPerColumn: 1, // Two rows
        slidesPerColumnFill: "row",
        onInit: function (swiper) {
          swiper.slideTo(1);
        },
        on: {
          reachEnd: (e) => {
            this.getNotificationSelectedUnitsOrGroups();
          }
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      }
    };
  },
  props: [
    "selectedUnitsData",
    "removeUnits",
    "alertId",
    "isReport",
    "isEditable",
    "isNotificationAvailable",
    "getNotificationSelectedUnitsOrGroups",
    "totalLookUpCount",
    "sliderConfiguration"
  ],
  created() {
    if (this.sliderConfiguration) {
      this.swiperOptions = {
        ...this.swiperOptions,
        ...this.sliderConfiguration
      };
    }
  },

  methods: {
    SupportedUnits(value, key, group) {
      if (value) {
        this.unitKey = key && key.toUpperCase();
        this.unitKey = this.unitKey === "UNITS" ? "ALL" : this.unitKey;
        this.groupId = group.id;
        this.$bvModal.show("supported-unit-list");
      }
    },

    formattedDescriptions(description, len) {
      return description && description.length > len
        ? `${description.slice(0, len).trim()}...`
        : description;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/swiper.scss";
@import "~swiper/css/swiper.css";
</style>
<style lang="scss">
.custom-card {
  min-height: 120px;
  min-width: 200px;
  padding: 15px 15px !important;
}
.side-custom {
  padding-left: 0px;
  font-size: 12px;
  margin-right: 5px;
}
.swiper-centered-slides.swiper-container {
  .swiper-slide {
    border: 2px solid transparent;
    text-align: left;
    .media-body {
      align-self: center;
    }
    .mr-bt-12 {
      margin-bottom: 15px;
    }
    .mr-bt-15 {
      margin-bottom: 18px;
    }
    &.swiper-slide-active {
      border: 2px solid transparent;
    }
    &:hover {
      border: 2px solid var(--primary);
    }
  }
  .min-height-18 {
    min-height: 18px;
  }
}
.u-lr-card {
  .media {
    .media-body {
      flex: 0 !important;
      text-align: start !important;
      width: 100% !important;
    }
  }
}
@media screen and (max-width: 991px) {
  .custom-card {
    min-height: 160px;
    min-width: 240px;
  }
  .mobile-wrap {
    flex-wrap: wrap;
    .side-custom {
      width: 100%;
    }
  }
}
.u-lr-card {
  .media {
    .media-body {
      flex: 0 !important;
      text-align: start !important;
      width: 100% !important;
    }
  }
  .media .media-body {
    min-width: calc(100% - 65px) !important;
  }
}
</style>
